.container{
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    height: 100%;
    width: 100%;
}

.leftSide{
    width: 35%;
    max-width: 350px;
    padding: 20px;
    background: #fafafa;
    border-right: 2px solid #eee;
    overflow: auto;
}

.rightSide{
    width: 100%;
    height: 100%;
}

.checkboxes{
    background: #fff;
    border-radius: 5px;
    padding: 10px;
}

.checkboxesSplitter{
    height: 10px;
}

h1{
    font-size: 22px;
    text-align: center;
    padding-bottom: 30px;
}

.checkboxDivTitle{
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 10px;
}
.checkboxDiv{
    padding: 5px 0;
}
.checkboxDiv label{
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: left;
}
.justifyDiv{
    display: flex;
    justify-content: space-evenly;
    margin-right: 20px;
    margin-left: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.justifyDiv label{
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.justifyDiv .fullWidth{
    width: 100% !important;
}

.checkboxDiv input[type=checkbox],
.checkboxDiv input[type=radio]
{
    margin-right: 10px;
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
}


.checkboxDiv input[type=number],
.checkboxDiv input[type=text]{
    padding: 2px  0px 2px 10px;
    width: 80px;
    margin-right: 10px;
}

.checkboxDiv input[type=text]{
    text-transform: uppercase;
    text-align: center;
}

.inputTime{
    justify-content: center !important;
}

.inputTime input{
    font-size: 16px;
    padding: 3px;
}

.messageInfo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
}

.loadingText{
    padding-left: 10px;
    font-weight: 600;
}
.center{
    text-align: center;
}

.labelInfo{
    font-size: 18px;
    font-weight: 600;
}

.resultInfo{
    background: #fff;
    padding: 9px;
    border-radius: 8px;
    margin-bottom: 2px;
    line-height: 20px;
    border: 1px solid #eee;
}
.legInfo{
    padding: 10px;
    background: #fefffe;
    border-radius: 10px;
    box-shadow: 3px 3px 2px #eee;
    margin: 5px 0px 5px 15px;
}

.legInfo ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.legInfo ul li{
    margin: 5px 0;
}
.legInfo ul li:before{
    content: ' ';
    background: #add99c;
    border-radius: 5px;
    margin-right: 10px;
    color: red;
    display: inline-block;
    width: 10px;
    height: 10px;
}

.textMessage{
    text-align: center;
    color: white;
    font-weight: 600;
    background: #ff7c7c;
    padding: 10px;
    border-radius: 10px;
    /*margin-left: -10px;*/
}
