.rowItem{
    padding: 6px 14px;
}

.rowItem:hover{
    background: #fafafa;
    cursor: pointer;
}

.listAutosuggest{
    border: 1px solid #ffb700;
    max-height: 300px;
    width: auto;
    display: block;
    overflow: auto;
    position: absolute;
    z-index: 100;
    background: #fff;
    padding-top:5px ;
    padding-bottom:5px ;
    margin-top: -7px;
    border-radius: 0px 0px 10px 10px;
    font-size: 1rem;
}

.listAutosuggestHide{
    display: none;
}

.autosuggestValue{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 11px rgb(0 0 0 / 10%);
    padding: .9rem;

}

.autosuggestValue input{
    border: unset;
    padding: 2px 10px;
    width: calc(100% - 1.8rem);
    outline:none;
    font-size: 1rem !important;

}
