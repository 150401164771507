@import './fonts/firasans.css';

body, html{
  margin: 0;
  font-family: 'Fira Sans', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Verdana,
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  font-weight: normal;
  font-size: 15px;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}



.cloud {
  border:1px solid #f0f0f0;
  border-radius:5px;
  height:auto;
  max-width: 250px;
  width: auto;
  padding:5px 14px 5px 9px;
  position:absolute;
  box-shadow: 3px 3px 7px #eee;
  font-family: Verdana ;
  font-size: 13px;
  color: #666;
  background: #fff;
  user-select: none;
  right: 7px;
  white-space: nowrap;
}
.cloud:before {
  color:#fff;
  border-bottom-color:#fff;
  position:absolute;
  right:-9px;
  top:5px;
  content:'▶';
  text-shadow: 3px 3px 3px #eee;
}

.default-marker-path {
  background-image: url(images/marker-icon.png);
}

.closePoint{
 margin-top: -22px;
  right: 3px;
  font-size: 10px;
  display: block;
  position: absolute;
  color: red;
}

.closePoint:hover{
  font-weight: 600;
}
