html, body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

#react-content {
	width: 100%;
	height: 100%;
} 

.leaflet-container  {
	width: 100%;
	height: 100%;
}

#RoutesPanel {
	position: absolute;
    left: 50px;
    top: 10px;
    background-color: #fff;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 5px 2px rgba(206,206,206,1);
    -moz-box-shadow: 0px 0px 5px 2px rgba(206,206,206,1);
    box-shadow: 0px 0px 5px 2px rgba(206,206,206,1);
    font-size: 14px;
	z-index: 1001;
}

#lengthsPanel {			
	border: solid 1px #cecece;
	border-collapse: collapse;
	width: 100%;
}

#lengthsPanel td { 
	border: solid 1px #cecece;
	padding: 3px 5px;
}



.marker {
	 border: solid 3px #0099E1;
	 padding: 3px;
	 border-radius: 7px;
	 background-color: rgba(255, 255, 255, 0.75);
}



