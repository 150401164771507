.abstractWindow{
    display: flex;
    position: fixed;
    right: 10px;
    top: 10px;
    width: 370px;
    min-height: 150px;
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 30%);
    z-index: 1;
    border-radius: 5px;
}


.classes_bk {
    background-color: #ffd742;
}

.classes_sk {
    background-color: #23ff8e;
}

.classes_ttk {
    background-color: #b8e8ff;
}

.classes_mkad {
    background-color: #ffbcbc;
}

.classes_ckad {
    background-color: #fbffc6;
}

.windowContainer{
    padding: 10px;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 15px;

}

#colors {
    width: 310px;
    display: flex;
    padding-bottom: 15px;
}

.square{
    width: 40px;
    padding: 5px;
    text-align: center;
    border: 1px solid #333;
    margin-right: 5px;
    margin-left: 5px;
}
.divWithTable{
    width: 100%;
}

.divWithTable table{
    width: 100%;
    text-align: center;
}

.mkad_fs{
    font-size: 13px;
    letter-spacing: -0.3px;
}
